import React from 'react';
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={window.__POWERED_BY_QIANKUN__ ? '/finance' : '/'}>
        React(MicroApp) Finance
      </BrowserRouter>
    </div>
  );
}

export default App;
